import '../../styles/components/textfield.scss';

const Textfield = (props) => {
    const { value, onChange, placeholder, type, classNames } = props;
    const handleChange = (e) => {
        e.preventDefault();
        onChange(e.target.value);
    }

    return <div className={`input-wrapper ${classNames}`}><input value={value} onChange={handleChange} placeholder={placeholder} type={type} /></div>;
};

Textfield.defaultProps = {
    type: 'text',
    classNames: '',
}

export default Textfield;