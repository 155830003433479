import React, { useState } from 'react';
import FilterItem from './FilterItem';
import './filter-group.scss';
import { useFilter } from './context/FilterContext';
import { ReactComponent as Search } from './search.svg';
import { useTranslation } from 'react-i18next';

export default function FilterGroup(props) {
    const { searchString, setSearchString, resetFilter } = useFilter();
    const { t } = useTranslation('Filter');
    const { filterCategories, searchPlaceholder } = props;

    const [openFilter, setOpenFilter] = useState(null);

    const toggleOpen = filterKey => {
        if (openFilter === filterKey) {
            setOpenFilter(null);
        } else {
            setOpenFilter(filterKey);
        }
    };

    return (
        <div>
            <div className='filter-group'>
                {filterCategories.length > 0
                    ? filterCategories.map(item => (
                          <FilterItem
                              key={item.slug}
                              label={item.label}
                              filterKey={item.slug}
                              alphabetically={item?.alphabetically}
                              isOpen={openFilter === item.slug}
                              toggleOpen={() => toggleOpen(item.slug)}
                          />
                      ))
                    : null}
            </div>
            <div className='search-input'>
                <button
                    onClick={() => resetFilter()}
                    className='btn btn-primary'
                >
                    {t('zurücksetzen')}
                </button>
            </div>
        </div>
    );
}
