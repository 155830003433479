import {
    createDirectus,
    rest,
    readItems,
    createItem,
    uploadFiles,
} from '@directus/sdk';
import type { Schema } from '../interfaces/types';

export const directus = createDirectus<Schema>(
    'https://api.swiss-cycling-jobboerse.ch',
).with(rest());

export const directusApi = {
    getTrainers: async () => {
        return await directus.request(
            readItems('trainer_items', {
                fields: [
                    'id',
                    'status',
                    'language',
                    'vorname',
                    'nachname',
                    'email',
                    'wohnkanton',
                    'wohnort',
                    'telefon',
                    'webseite',
                    'philosophie',
                    'erfahrung_freitext',
                    'disziplinen',
                    'ausbildung',
                    'angebot',
                    'erfahrung',
                    'sprachen',
                    'image',
                    'date_created',
                ],
                filter: {
                    status: {
                        _eq: 'published',
                    },
                },
            }),
        );
    },

    uploadFile: async (file: File) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            const result = await directus.request(uploadFiles(formData));
            return { success: true, data: result };
        } catch (error: any) {
            return { success: false, error: error.message };
        }
    },

    createTrainer: async (
        data: any,
        language: string,
        file_id: string | null,
    ) => {
        try {
            const result = await directus.request(
                createItem('trainer_items', {
                    ...data,
                    language,
                    image: file_id,
                }),
            );

            // if (process.env.NODE_ENV === 'production') {
            //     let _paq = (window._paq = window._paq || []);
            //     _paq.push([
            //         'trackEvent',
            //         'Neuer Eintrag',
            //         'Neuer Eintrag',
            //         data.type,
            //     ]);
            // }

            return { success: true, data: result };
        } catch (error: any) {
            return { success: false, error: error.message };
        }
    },
};
