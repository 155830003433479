import { createSlice } from '@reduxjs/toolkit';
import { fetchItems } from '../thunks/itemThunks';
import type { TrainerItem } from '../../interfaces/types.ts';

interface ItemsState {
    trainerItems: TrainerItem[];
    loading: boolean;
    error: string | null;
}

const initialState: ItemsState = {
    trainerItems: [],
    loading: false,
    error: null,
};

const itemsSlice = createSlice({
    name: 'trainerItems',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchItems.pending, state => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchItems.fulfilled, (state, action) => {
                state.loading = false;
                state.trainerItems = action.payload;
            })
            .addCase(fetchItems.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'An error occurred';
            });
    },
});

export default itemsSlice.reducer;
