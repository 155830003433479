import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import anmeldungDe from './translation/de/erfassen-seite.json';
import anmeldungFr from './translation/fr/erfassen-seite.json';
import headerDe from './translation/de/header.json';
import headerFr from './translation/fr/header.json';
import footerDe from './translation/de/footer.json';
import footerFr from './translation/fr/footer.json';
import loginDe from './translation/de/login.json';
import loginFr from './translation/fr/login.json';
import filterDe from './translation/de/Filter.json';
import filterFr from './translation/fr/Filter.json';
import configDe from './translation/de/config.json';
import configFr from './translation/fr/config.json';

const i18nInit = i18n
    .use(initReactI18next)
    .init({
        resources: {
            de: {
                'erfassen-seite': anmeldungDe,
                header: headerDe,
                footer: footerDe,
                login: loginDe,
                Filter: filterDe,
                config: configDe,
            },
            fr: {
                'erfassen-seite': anmeldungFr,
                header: headerFr,
                footer: footerFr,
                login: loginFr,
                Filter: filterFr,
                config: configFr,
            },
        },
        lng: 'de',
        fallbackLng: 'de',
        debug: false,
    });

export default i18n;
export { i18nInit };
