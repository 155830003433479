import React, { useState, useEffect } from 'react';
import './modal.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePosts } from '../../hooks/usePosts';
import { TrainerItem } from '../../interfaces/types';

import { useLocation } from 'react-router-dom';

interface ListProps {
    data: string;
}

const List: React.FC<ListProps> = ({ data }) => {
    const { t } = useTranslation(['config']);

    if (data === '[[]]') return null;

    const ausbildungArrayToText = (arr: string) => {
        if (arr === '[]') return null;
        const parsedArray = JSON.parse(arr);
        return parsedArray.map((item: string, index: number) => (
            <li key={index}>{t(item)}</li>
        ));
    };

    return <ul className='ausbildung'>{ausbildungArrayToText(data)}</ul>;
};

interface ModalProps {
    title?: string;
    children?: React.ReactNode;
}

const TrainerfinderModal: React.FC<ModalProps> = ({
    title = 'Modal Title',
    children,
}) => {
    const [item, setItem] = useState<TrainerItem | null>(null);
    const navigate = useNavigate();
    const { t } = useTranslation(['erfassen-seite']);
    const { id } = useParams<{ id: string }>();
    const { getItemById, loading, error } = usePosts();
    const location = useLocation();

    const onClose = () => {
        if (location.state?.background) {
            navigate(location.state.background, { replace: true });
        } else {
            navigate(-1);
        }
    };

    useEffect(() => {
        const fetchedItem = getItemById(Number(id));
        setItem(fetchedItem || null);
    }, [getItemById, id]);

    useEffect(() => {
        if (!location.state?.background) {
            navigate('/'); // Redirect to home if background is not set
        }
    }, [location, navigate]);

    // Render nothing until the redirection occurs
    if (!location.state?.background) {
        return null;
    }

    if (loading) {
        return null;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!item) {
        return null;
    }

    const date = new Date(item.date_created).toLocaleDateString('de-CH');

    return (
        <div
            className='modal-overlay'
            onClick={onClose}
        >
            <div
                className='modal-content'
                onClick={e => e.stopPropagation()}
            >
                <button
                    className='close-button'
                    onClick={onClose}
                >
                    <img
                        src={process.env.PUBLIC_URL + '/assets/close.svg'}
                        alt='arrow-filter'
                    />
                </button>

                <div
                    className='boerse-item two-columns trainerfinder'
                    key={item.id}
                >
                    <div className='column image-column'>
                        {item.image !== undefined && item.image !== null && (
                            <div className='image-wrapper'>
                                <img
                                    src={`https://api.swiss-cycling-jobboerse.ch/assets/${item.image}`}
                                />
                            </div>
                        )}
                    </div>

                    <div className='column'>
                        <div>
                            <div className='boerse-item__tag boerse-item__tag--date'>
                                {date}
                            </div>
                            <h2>{`${item.vorname} ${item.nachname}`}</h2>
                        </div>

                        <h3>{t('Angebot')}</h3>
                        {item.angebot !== null && item.angebot.includes('[') ? (
                            <List data={item.angebot} />
                        ) : (
                            <div>{item.disziplinen}</div>
                        )}

                        <h3>{t('Disziplinen')}</h3>
                        {item.disziplinen !== null &&
                        item.disziplinen.includes('[') ? (
                            <List data={item.disziplinen} />
                        ) : (
                            <div>{item.disziplinen}</div>
                        )}

                        {item.philosophie ? (
                            <div>
                                <h3>{t('Trainerphilosophie')}</h3>
                                <p>{item.philosophie}</p>
                            </div>
                        ) : null}

                        <h3>{t('Erfahrung als Trainer/-in')}</h3>
                        {item.erfahrung !== null &&
                        item.erfahrung.includes('[') ? (
                            <List data={item.erfahrung} />
                        ) : (
                            <div>{item.erfahrung}</div>
                        )}

                        {item.erfahrung_freitext ? (
                            <ul className='ausbildung'>
                                <li>{item.erfahrung_freitext}</li>
                            </ul>
                        ) : null}

                        <h3>{t('Ausbildung')}</h3>
                        {item.ausbildung !== null &&
                        item.ausbildung.includes('[') ? (
                            <List data={item.ausbildung} />
                        ) : (
                            <div>{item.disziplinen}</div>
                        )}

                        <h3>{t('Sprachen')}</h3>
                        {item.sprachen !== null &&
                        item.sprachen.includes('[') ? (
                            <List data={item.sprachen} />
                        ) : (
                            <div>{item.sprachen}</div>
                        )}

                        <h3>{t('Kontakt')}</h3>
                        <div>
                            {item.email && item.email !== '' && (
                                <a href={`mailto:${item.email}`}>
                                    {item.email}
                                </a>
                            )}
                        </div>
                        <div>
                            {item.webseite && item.webseite !== '' && (
                                <a
                                    href={item.webseite}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    {item.webseite}
                                </a>
                            )}
                        </div>
                        <div>
                            {item.telefon && item.telefon !== '' && (
                                <a href={`tel:${item.telefon}`}>
                                    {item.telefon}
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrainerfinderModal;
