import { Route, Routes, Navigate, Outlet, useLocation } from 'react-router-dom';
import TrainerfinderModal from '../components/Modal/TrainerfinderModal';
import { TrainerBoerse, Impressum, TrainerfinderNeuerEintrag, Login, Success, EditOverview, UpdateStatus } from '../pages';

const ReactRoutes = () => {

    const location = useLocation();
    const background = location.state && location.state.background;


    return (

        <>

            <Routes location={background || location}>
                <Route path="/" element={<Navigate replace to="/de/" />} />

                <Route path="/:lang">

                    {/* Main Routes */}
                    <Route
                        path=''
                        element={<TrainerBoerse />}
                    />
                    <Route path="neuer-eintrag" element={<TrainerfinderNeuerEintrag />} />

                    {/* Edit Routes */}
                    <Route path="login" element={<Login />} />
                    <Route path="/:lang/eintrag-bearbeiten/:id" element={<TrainerfinderNeuerEintrag />} />
                    <Route path="eintrag-bearbeiten" element={<EditOverview />} />

                    {/* Static Pages */}
                    <Route path="success" element={<Success />} />
                    <Route path='impressum' element={<Impressum />} />

                    {/* Admin Routes */}
                    <Route path="update-status" element={<UpdateStatus />} />
                </Route>

                <Route path="/:lang/modal/:id" element={<TrainerfinderModal />} />

            </Routes>

            {
                background && (
                    <Routes>
                        <Route path="/:lang/modal/:id" element={<TrainerfinderModal />} />
                    </Routes>
                )
            }

            <Outlet />

        </>
    );
};

export default ReactRoutes;
