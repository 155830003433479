import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Button from '../../components/InputFields/Button';

const UpdateStatus = () => {
    const handleSubmit = (e: any) => {
        e.preventDefault();
        console.log('Status bearbeiten');
    };
    return (
        <div className='login-page'>
            <div>
                <Header />

                <div className='login-form'>
                    <h1>Eintrag veröffentlichen</h1>
                    <p>Hier kannst du den einen Eintrag veröffentlichen.</p>
                    <form
                        onSubmit={e => {
                            handleSubmit(e);
                        }}
                    >
                        <Button
                            type='submit'
                            value={'Veröffentlichen'}
                        />
                    </form>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default UpdateStatus;
