import React from 'react';
import { useError } from './ErrorContext';
import '../components/MessageBox/message-box.scss';
const ErrorDisplay = () => {
    const { err, msg, visible } = useError();

    return (
        visible && (
            <div className={`message-box-wrapper ${alert ? '' : 'inline'}`}>
                <div className={`message-box ${visible ? 'active' : ''} ${err ? 'error' : ''}`}>
                    {msg}
                </div>
            </div>

        )

    );
};


export default ErrorDisplay;
