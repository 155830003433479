import React from 'react';
import { useTranslation } from 'react-i18next';
import { TrainerItem } from '../../interfaces/types';
import TrainerFinderTease from '../TrainerFinderTease/TrainerFinderTease';

interface PreviewContainerProps {
    data: TrainerItem[];
    postType: string;
}

const PreviewContainer = ({ data, postType }: PreviewContainerProps) => {
    const { t } = useTranslation('Filter');

    const renderItems = () =>
        data.map(item => (
            <TrainerFinderTease
                key={item.id}
                item={item}
            />
        ));

    if (!data || data.length === 0) {
        return (
            <div className='items-preview-empty'>
                {t('Keine Einträge gefunden', {
                    defaultValue: 'No entries found',
                })}
            </div>
        );
    }

    return (
        <div className='preview-container'>
            <div className='preview-header'>
                <span className='number-of-items'>
                    {t('Anzahl')}: {data.length}
                </span>
            </div>
            <div className='items-preview'>{renderItems()}</div>
        </div>
    );
};

export default React.memo(PreviewContainer);
