import React, { useEffect, useReducer, useState } from 'react';
import '../../styles/pages/add-new.scss';
import Textfield from '../../components/InputFields/Textfield';
import Button from '../../components/InputFields/Button';
import Textarea from '../../components/InputFields/Textarea';
import Select from '../../components/InputFields/Select';
import { v4 as uuidv4 } from 'uuid';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import { useNavigate, useParams, Link } from 'react-router-dom';
import ImageUpload from '../../components/ImageUpload/ImageUpload';
import { useTranslation } from 'react-i18next';

import i18n from '../../i18n';
import Checkboxes from '../../components/InputFields/Checkboxes';
import Tooltip from '../../components/InputFields/Tooltip';

import { useError } from '../../context/ErrorContext';

import { useDirectus } from '../../hooks/useDirectus';

function getUUID() {
    const urlParams = new URLSearchParams(window.location.search);
    let uuid = urlParams.get('uuid');

    if (!uuid) {
        uuid = localStorage.getItem('uuid');
    } else {
        localStorage.setItem('uuid', uuid);
    }

    return uuid;
}

const getToken = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let token = urlParams.get('token');

    if (!token) {
        token = localStorage.getItem('token');
    } else {
        localStorage.setItem('token', token);
    }

    return token;
};

const options = [
    {
        value: 'none',
        text: 'Organisationsform',
        disabled: 'disabled',
        selected: 'defaultValue',
        hidden: 'hidden',
    },
    {
        value: 'Verein tätig im Radsport',
        text: 'Verein tätig im Radsport',
    },
    { value: 'Swiss Cycling Guide', text: 'Swiss Cycling Guide' },
    {
        value: 'Swiss Cycling Guide Partnerbetrieb',
        text: 'Swiss Cycling Guide Partnerbetrieb',
    },
];

const kantone = [
    {
        value: 'none',
        text: 'Wohnkanton*',
        disabled: 'disabled',
        selected: 'defaultValue',
        hidden: 'hidden',
    },
    { value: 'Aargau', text: 'Aargau' },
    { value: 'Appenzell Ausserrhoden', text: 'Appenzell Ausserrhoden' },
    { value: 'Appenzell Innerrhoden', text: 'Appenzell Innerrhoden' },
    { value: 'Basel-Landschaft', text: 'Basel-Landschaft' },
    { value: 'Basel-Stadt', text: 'Basel-Stadt' },
    { value: 'Bern', text: 'Bern' },
    { value: 'Freiburg', text: 'Freiburg' },
    { value: 'Genf', text: 'Genf' },
    { value: 'Glarus', text: 'Glarus' },
    { value: 'Graubünden', text: 'Graubünden' },
    { value: 'Jura', text: 'Jura' },
    { value: 'Luzern', text: 'Luzern' },
    { value: 'Neuenburg', text: 'Neuenburg' },
    { value: 'Nidwalden', text: 'Nidwalden' },
    { value: 'Obwalden', text: 'Obwalden' },
    { value: 'Schaffhausen', text: 'Schaffhausen' },
    { value: 'Schwyz', text: 'Schwyz' },
    { value: 'Solothurn', text: 'Solothurn' },
    { value: 'St. Gallen', text: 'St. Gallen' },
    { value: 'Tessin', text: 'Tessin' },
    { value: 'Thurgau', text: 'Thurgau' },
    { value: 'Uri', text: 'Uri' },
    { value: 'Waadt', text: 'Waadt' },
    { value: 'Wallis', text: 'Wallis' },
    { value: 'Zug', text: 'Zug' },
    { value: 'Zürich', text: 'Zürich' },
    { value: 'Ausland', text: 'Ausland' },
];

const sportarten = [
    'Bahn',
    'BMX',
    'MTB',
    'MTB Gravity',
    'Radquer',
    'Strasse',
    'Trial',
];

const js_ausbildung = [
    'J+S-Weiterbildung 2 (Einführung in den Leistungssport) ',
    'Trainer/-in Leistungssport mit eidg. Fachausweis (BTL)',
    'Trainer/-in Spitzensport mit eidg. Diplom (DTL)',
];

const angebot = [
    'Individuelle Trainings- & Wettkampfanalyse, -planung & -steuerung',
    'Beratungsgespräche',
    'Leistungsdiagnostik',
    'Individuelle Trainings (z.B. Techniktrainings)',
    'Gruppentrainings',
    'Begleitung an Wettkämpfe',
    'Krafttraining',
];

const initialState = {
    vorname: '',
    nachname: '',
    email: '',
    organisation: '',
    titel: '',
    type: 'suche',
    adresse: '',
    plz: '',
    ort: '',
    termine: '',
    js_ausbildung: [],
    scg_ausbildung: [],
};

function reducer(state, action) {
    switch (action.type) {
        case 'UPDATE':
            return {
                ...state,
                [action.key]: action.value,
            };
        case 'REPLACE':
            return action.payload;
        default:
            return state;
    }
}

const checkIfNotEmpty = state => {
    if (
        state.vorname === '' ||
        state.nachname === '' ||
        state.email === '' ||
        (state.organisation === '' && state.type === 'suche') ||
        (state.organisationsform === 'none' && state.type === 'suche') ||
        state.adresse === '' ||
        state.plz === '' ||
        state.ort === '' ||
        state.titel === '' ||
        state.beschreibung === '' ||
        (state.js_ausbildung.length === 0 &&
            state.scg_ausbildung.length === 0) ||
        state.sportart.length === 0 ||
        state.availability.length === 0
    ) {
        return false;
    }

    return true;
};

const erfahrung_options = [
    'Trainer/-in von Jugendlichen (ab U17)',
    'Trainer/-in von erwachsenen Breiten- & Leistungssportler/-innen',
    'Trainer/-in von Paracycling-Athlet/-innen',
];

export default function AddNew() {
    const { t } = useTranslation('erfassen-seite');

    const { language } = useParams();

    const [reducerState, dispatch] = useReducer(reducer, initialState);
    const navigate = useNavigate();

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const { setErrorMessage, setSuccessMessage } = useError();

    const [vornameError, setVornameError] = useState(false);
    const [nachnameError, setNachnameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [loading, setLoading] = useState(false);

    const { id } = useParams();

    const { uploadFile, createTrainer } = useDirectus();

    const createUUID = () => {
        return uuidv4();
    };

    useEffect(() => {
        const uuid = getUUID();

        if (!uuid) {
            console.error('UUID is missing');
            return;
        }

        const fetchData = async () => {
            if (id === undefined) return;
            try {
                const payload = { uuid };

                const token = getToken();

                if (token) {
                    payload.token = token;
                }

                const response = await fetch(
                    'https://api.swiss-cycling-jobboerse.ch/trainerfinder/get',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload),
                    },
                );

                console.log(response);

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const items = await response.json();

                const item = items.filter(item => item.id === parseInt(id));
                setReducerState(item[0]);

                //setData(items);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const customJSONParse = data => {
        if (data.includes('[')) {
            return JSON.parse(data);
        } else {
            return [data];
        }
    };

    const setReducerState = data => {
        const tmpData = {
            ...data,
        };

        dispatch({
            type: 'REPLACE',
            payload: tmpData,
        });
    };

    const addFile = async file => {
        const result = await uploadFile(file);
        if (result.success) {
            return result.data;
        } else {
            setErrorMessage(result.error);
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        if (reducerState.vorname === '') {
            setErrorMessage('Bitte fülle alle Pflichtfelder aus.');
            setVornameError(true);
            return;
        }
        setVornameError(false);

        if (reducerState.nachname === '') {
            setErrorMessage('Bitte fülle alle Pflichtfelder aus.');
            setNachnameError(true);
            return;
        }
        setNachnameError(false);

        if (reducerState.email === '') {
            setErrorMessage('Bitte fülle alle Pflichtfelder aus.');
            setEmailError(true);
            return;
        }
        setEmailError(false);

        if (!reducerState.sprachen || reducerState.sprachen.length === 0) {
            setErrorMessage('Bitte wähle mindestens eine Sprache aus.');
            return;
        }

        if (!reducerState.file && !getToken()) {
            setErrorMessage('Bitte lade ein Bild hoch.');
            return;
        }

        if (!reducerState.angebotsbedingungen) {
            setErrorMessage(
                'Um deinen Eintrag einzureichen, musst du die Angebotsbedingungen akzeptieren.',
            );
            return;
        }

        if (!reducerState.datenschutz) {
            setErrorMessage(
                'Um deinen Eintrag einzureichen, musst du die Datenschutzbestimmungen akzeptieren.',
            );
            return;
        }

        if (!reducerState.angebot || reducerState.angebot.length === 0) {
            setErrorMessage('Bitte wähle mindestens ein Angebot aus.');
            return;
        }

        if (
            !reducerState.disziplinen ||
            reducerState.disziplinen.length === 0
        ) {
            setErrorMessage('Bitte wähle mindestens eine Disziplin aus.');
            return;
        }

        if (
            !reducerState.philosophie ||
            reducerState.philosophie.length === 0
        ) {
            setErrorMessage('Bitte fülle das Feld "Philosophie" aus.');
            return;
        }

        if (
            (!reducerState.erfahrung || reducerState.erfahrung.length === 0) &&
            !reducerState.erfahrung_freitext
        ) {
            setErrorMessage('Bitte wähle mindestens eine Erfahrung aus.');
            return;
        }

        if (!reducerState.ausbildung || reducerState.ausbildung.length === 0) {
            setErrorMessage('Bitte wähle mindestens eine Ausbildung aus.');
            return;
        }

        // if (!checkIfNotEmpty(reducerState)) {
        //     setErrorMessage('Bitte fülle alle Felder aus.');
        //     return;
        // }

        // if (reducerState.beschreibung.length < 50) {
        //     setErrorMessage(
        //         'Die Beschreibung sollte mindestens 50 Zeichen lang sein.',
        //     );
        //     return;
        // }

        if (!reducerState.datenschutz) {
            setErrorMessage(
                'Um deinen Eintrag einzureichen, musst du die Datenschutzbestimmungen akzeptieren.',
            );
            return;
        }

        let file_id = null;
        if (reducerState.file) {
            const response = await addFile(reducerState.file);
            file_id = response.id;
        }

        const uuid = createUUID();

        const payload = {
            ...reducerState,
            uuid,
        };

        const createPost = async () => {
            const result = await createTrainer(payload, i18n.language, file_id);

            if (result.success) {
                setSuccessMessage('Erfolgreich gespeichert.');
                navigate('/');
            } else {
                setErrorMessage(result.error);
            }
        };

        const updatePost = async (reducerState, file_id) => {
            const uuid = localStorage.getItem('uuid');

            const payload = { ...reducerState };
            if (file_id) {
                payload.image = file_id;
            }

            const data = {
                data: payload,
                uuid: uuid,
                id: id,
            };
            const token = getToken();

            if (token) {
                data.token = token;
            }

            try {
                const response = await fetch(
                    'https://api.swiss-cycling-jobboerse.ch/trainerfinder/update',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data),
                    },
                );

                if (response.ok) {
                    console.log('Post updated successfully');
                    setSuccessMessage('Erfolgreich gespeichert.');
                } else {
                    console.error('Failed to update post');
                    setErrorMessage('Failed to update post');
                }
            } catch (error) {
                console.error('Error updating post:', error);
                setErrorMessage('Failed to update post');
            }
        };

        if (id === undefined) {
            createPost();
        } else {
            updatePost(reducerState, file_id);
        }
    };

    useEffect(() => {
        if (reducerState.type === 'biete') {
            dispatch({
                type: 'UPDATE',
                value: t('biete_titel_placeholder'),
                key: 'titel',
            });
        } else {
            dispatch({
                type: 'UPDATE',
                value: '',
                key: 'titel',
            });
        }
    }, [reducerState.type]);

    useEffect(() => {
        if (reducerState?.plz?.length < 4 || reducerState?.plz?.length > 4) {
            return;
        }

        const fetchPlz = async () => {
            fetch(
                `https://nominatim.openstreetmap.org/search?postalcode=${reducerState.plz}&country=Switzerland&format=json&addressdetails=1`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
            )
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    if (data.length > 0) {
                        dispatch({
                            type: 'UPDATE',
                            value: data[0].address.state,
                            key: 'kanton',
                        });
                        const coordinates = {
                            type: 'Point',
                            coordinates: [
                                parseFloat(data[0].lon),
                                parseFloat(data[0].lat),
                            ],
                        };
                        dispatch({
                            type: 'UPDATE',
                            value: coordinates,
                            key: 'coordinates',
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        };

        fetchPlz();
    }, [reducerState.plz]);

    const publishEntry = async () => {
        const uuid = localStorage.getItem('uuid');

        const payload = { status: 'published' };

        const data = {
            data: payload,
            uuid: uuid,
            id: id,
        };

        const token = getToken();

        if (token) {
            data.token = token;
        }

        try {
            const response = await fetch(
                'https://api.swiss-cycling-jobboerse.ch/trainerfinder/update',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                },
            );

            if (response.ok) {
                setSuccessMessage('Erfolgreich veröffentlicht.');
                navigate('/de/eintrag-bearbeiten');
            } else {
                console.error('Failed to update post');
                setErrorMessage('Eintrag konnte nicht gelöscht werden.');
            }
        } catch (error) {
            console.error('Error updating post:', error);
            setErrorMessage('Failed to update post');
        }
    };

    const deleteEntry = async () => {
        const uuid = localStorage.getItem('uuid');

        const payload = { status: 'deleted' };

        const data = {
            data: payload,
            uuid: uuid,
            id: id,
        };

        const token = getToken();

        if (token) {
            data.token = token;
        }

        try {
            const response = await fetch(
                'https://api.swiss-cycling-jobboerse.ch/trainerfinder/update',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                },
            );

            if (response.ok) {
                setSuccessMessage('Erfolgreich gelöscht.');
                navigate('/de/eintrag-bearbeiten');
            } else {
                console.error('Failed to update post');
                setErrorMessage('Eintrag konnte nicht gelöscht werden.');
            }
        } catch (error) {
            console.error('Error updating post:', error);
            setErrorMessage('Failed to update post');
        }
    };

    const tooltip = {
        1: t(
            'Z.B. Trainingsanalyse, Wettkampfvorbereitung, Karriereplanung, Stärken-Schwächen-Profil des Athleten',
        ),
    };

    const sprachen = ['Deutsch', 'Französisch', 'Italienisch', 'Englisch'];

    return (
        <>
            <Header />

            <div className='add-new content'>
                <div className='content'>
                    {id === undefined ? (
                        <div>
                            <h2>{t('erfassungsregeln_titel')}</h2>
                            <p>{t('trainerfinder_text')}</p>
                            <ul>
                                <li>{t('trainerfinder_text_1')}</li>
                                <li>{t('trainerfinder_text_2')}</li>
                                <li>{t('trainerfinder_text_3')}</li>
                            </ul>
                        </div>
                    ) : null}

                    <form
                        onSubmit={e => {
                            handleSubmit(e);
                        }}
                    >
                        <h2>{t('kontakdaten_titel')}</h2>
                        <div className='columns-2'>
                            <Textfield
                                value={reducerState.vorname}
                                onChange={e =>
                                    dispatch({
                                        type: 'UPDATE',
                                        value: e,
                                        key: 'vorname',
                                    })
                                }
                                placeholder={`${t('vorname')}*`}
                                classNames={vornameError ? 'error-input' : ''}
                            />
                            <Textfield
                                value={reducerState.nachname}
                                onChange={e =>
                                    dispatch({
                                        type: 'UPDATE',
                                        value: e,
                                        key: 'nachname',
                                    })
                                }
                                placeholder={`${t('nachname')}*`}
                                classNames={nachnameError ? 'error-input' : ''}
                            />
                        </div>
                        <Textfield
                            value={reducerState.email}
                            onChange={e =>
                                dispatch({
                                    type: 'UPDATE',
                                    value: e,
                                    key: 'email',
                                })
                            }
                            placeholder={`${t('Email')}*`}
                            classNames={emailError ? 'error-input' : ''}
                        />

                        <div>
                            <Select
                                options={kantone}
                                value={reducerState.wohnkanton}
                                onChange={e =>
                                    dispatch({
                                        type: 'UPDATE',
                                        value: e,
                                        key: 'wohnkanton',
                                    })
                                }
                            />
                        </div>
                        <Textfield
                            value={reducerState.wohnort}
                            onChange={e =>
                                dispatch({
                                    type: 'UPDATE',
                                    value: e,
                                    key: 'wohnort',
                                })
                            }
                            placeholder={t('wohnort')}
                            id='wohnort'
                        />

                        <Textfield
                            value={reducerState.webseite}
                            onChange={e =>
                                dispatch({
                                    type: 'UPDATE',
                                    value: e,
                                    key: 'webseite',
                                })
                            }
                            placeholder={t('webseite')}
                        />
                        <Textfield
                            value={reducerState.telefon}
                            onChange={e =>
                                dispatch({
                                    type: 'UPDATE',
                                    value: e,
                                    key: 'telefon',
                                })
                            }
                            placeholder={t('telefon')}
                        />
                        <div>
                            <h3>{t('Sprachen')}</h3>
                            <Checkboxes
                                items={sprachen}
                                checkedState={reducerState.sprachen}
                                dispatch={dispatch}
                                keyName='sprachen'
                            />

                            <h2>
                                {t('trainerfinder_bild_titel')}{' '}
                                <Tooltip text={t('trainerfinder_bild_tipp')} />
                            </h2>
                            <ImageUpload
                                handleFileChange={file =>
                                    dispatch({
                                        type: 'UPDATE',
                                        value: file,
                                        key: 'file',
                                    })
                                }
                            />

                            <h3>{t('Angebot')}</h3>
                            <Checkboxes
                                items={angebot}
                                tooltip={tooltip}
                                checkedState={reducerState.angebot}
                                dispatch={dispatch}
                                keyName='angebot'
                            />

                            <h3>{t('Disziplinen-Spezialisierung')}</h3>
                            <Checkboxes
                                items={sportarten}
                                checkedState={reducerState.disziplinen}
                                dispatch={dispatch}
                                keyName='disziplinen'
                            />

                            <h2>{t('angebot_titel')}</h2>

                            <p>{t('angebot_text')}</p>

                            <Textarea
                                value={reducerState.philosophie}
                                onChange={e =>
                                    dispatch({
                                        type: 'UPDATE',
                                        value: e,
                                        key: 'philosophie',
                                    })
                                }
                                placeholder={t('trainerfinder_philosophie')}
                            />

                            <h3>{t('Erfahrung als Trainer/-in')}</h3>
                            <Checkboxes
                                items={erfahrung_options}
                                checkedState={reducerState.erfahrung}
                                dispatch={dispatch}
                                keyName='erfahrung'
                            />

                            <Textarea
                                value={reducerState.erfahrung_freitext}
                                onChange={e =>
                                    dispatch({
                                        type: 'UPDATE',
                                        value: e,
                                        key: 'erfahrung_freitext',
                                    })
                                }
                                placeholder={t('erfahrung_freitext')}
                            />
                        </div>

                        <h3>
                            {t('Ausbildung')}{' '}
                            <Tooltip text='Der erfolgreiche Abschluss der J+S-Weiterbildung 2 (Einführung in den Leistungssport) ist die Mindestvoraussetzung für die Aufnahme in den Swiss Cycling Trainer finder.' />
                        </h3>
                        <Checkboxes
                            items={js_ausbildung}
                            checkedState={reducerState.ausbildung}
                            dispatch={dispatch}
                            keyName='ausbildung'
                        />

                        <div className='checkbox'>
                            <input
                                type='checkbox'
                                id='datenschutz'
                                name='datenschutz'
                                value='datenschutz'
                                onChange={e =>
                                    dispatch({
                                        type: 'UPDATE',
                                        value: e.target.checked,
                                        key: 'datenschutz',
                                    })
                                }
                            />
                            <label
                                htmlFor='datenschutz'
                                dangerouslySetInnerHTML={{
                                    __html: t('datenschutz'),
                                }}
                            />
                        </div>

                        <h2>{t('angebotsbedingungen_titel')}</h2>
                        <div
                            className='angebotsbedingungen'
                            style={{ marginBottom: 10 }}
                        >
                            {t('angebotsbedingungen_text')}
                        </div>

                        <div className='checkbox'>
                            <input
                                type='checkbox'
                                id='angebotsbedingungen'
                                name='angebtosbedingungen'
                                value='angebtosbedingungen'
                                onChange={e =>
                                    dispatch({
                                        type: 'UPDATE',
                                        value: e.target.checked,
                                        key: 'angebotsbedingungen',
                                    })
                                }
                            />

                            <label htmlFor='angebotsbedingungen'>
                                {t('angebotsbedingungen_gelesen')}
                            </label>
                        </div>

                        <div className='button-wrapper'>
                            <Button
                                type='submit'
                                value={t('speichern')}
                                disabled={loading}
                            />
                            {getUUID() && !getToken() ? (
                                <Button
                                    type='submit'
                                    value={t('das Angebot ist noch aktuell')}
                                />
                            ) : null}
                            {getUUID() && getToken() ? (
                                <button
                                    className='button'
                                    onClick={() => publishEntry()}
                                >
                                    {t('veröffentlichen')}
                                </button>
                            ) : null}
                            {getUUID() ? (
                                <button
                                    type='button'
                                    className='button'
                                    onClick={() => deleteEntry()}
                                >
                                    {t('Eintrag löschen')}
                                </button>
                            ) : null}
                        </div>

                        {error ? (
                            <div className='error'>{error.message}</div>
                        ) : null}
                        {success ? (
                            <div className='success'>
                                {t('Erfolgreich gespeichert')}.
                            </div>
                        ) : null}
                    </form>
                </div>
            </div>
            <Footer />
        </>
    );
}
