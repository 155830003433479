import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TrainerItem } from '../../interfaces/types';
import './items-preview.scss';
import './items-preview-2.scss';

interface TrainerFinderTeaseProps {
    item: TrainerItem;
}

export default function TrainerFinderTease({ item }: TrainerFinderTeaseProps) {
    const {
        id,
        vorname,
        nachname,
        disziplinen,
        wohnkanton,
        image,
        date_created,
        ausbildung,
    } = item;

    const date = new Date(date_created).toLocaleDateString('de-CH');
    const location = useLocation();
    const imageId = image?.id ? image.id : image;
    const isEdit = location.pathname.includes('eintrag-bearbeiten');

    const state = isEdit ? null : { background: location };
    const { t, i18n } = useTranslation('config');
    const currentLanguage = i18n.language;
    const link = isEdit
        ? `/${currentLanguage}/eintrag-bearbeiten/${id}`
        : `/${currentLanguage}/modal/${id}`;
    const List = ({ data }: { data: any }) => {
        if (data === '[[]]') return null;

        const ausbildungArrayToText = (arr: any) => {
            if (arr === '[]') return null;
            const parsedArray = JSON.parse(arr);
            return parsedArray.map((item: any) => {
                return <li key={item}>{t(item)}</li>;
            });
        };

        return <ul className='ausbildung'>{ausbildungArrayToText(data)}</ul>;
    };

    return (
        <div
            className='boerse-item'
            key={id}
        >
            <Link
                to={link}
                state={state}
            >
                {imageId !== null && (
                    <div className='boerse-item__image-wrapper'>
                        <img
                            src={`https://api.swiss-cycling-jobboerse.ch/assets/${imageId}`}
                            alt='trainer'
                        />
                    </div>
                )}

                <div className='content trainerfinder'>
                    <div>
                        <div className='boerse-item__tag boerse-item__tag--date'>
                            {date}
                        </div>
                        <h2>{`${vorname} ${nachname}`}</h2>
                    </div>

                    <div className='boerse-item__organisation'>
                        {wohnkanton}
                    </div>
                    <span className='btn'>{t('zum Trainerprofil')}</span>

                    <h5>{t('Ausbildung')}</h5>
                    {ausbildung != null && ausbildung?.includes('[') ? (
                        <List data={ausbildung} />
                    ) : (
                        <div>{disziplinen}</div>
                    )}

                    <h5>{t('Disziplinen')}</h5>
                    {disziplinen != null && disziplinen?.includes('[') ? (
                        <List data={disziplinen} />
                    ) : (
                        <div>{disziplinen}</div>
                    )}
                </div>
            </Link>
        </div>
    );
}
