import { useEffect } from 'react';
import LoginForm from '../../components/LoginForm/LoginForm';

import { useNavigate } from 'react-router-dom';

const Login = () => {
    return (
        <div className='login-page'>
            <div className='login-page--left'>
                <LoginForm />
            </div>
            <div className='login-page--right'></div>
        </div>
    );
};

export default Login;
