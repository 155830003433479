import React from 'react';
import './loading-spinner.scss';

const LoadingSpinner = () => {
    return (
        <div className='spinner-container'>
            <div className='spinner'></div>
        </div>
    );
};

export default LoadingSpinner;
