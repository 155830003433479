import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { store } from './redux/store';

import './App.css';
import ReactRoutes from './routes';

import { i18nInit } from './i18n';

import './styles/filter-group.scss';
import './styles/filter-item.scss';
import { BrowserRouter } from 'react-router-dom';
//import './styles/item.scss';
//import './styles/preview-container.scss';
import './styles/components/textfield.scss';
import './styles/containers/login-form.scss';

import ErrorDisplay from './context/ErrorDisplay';

function App() {
    console.log(i18nInit);

    return (
        <Provider store={store}>
            <BrowserRouter>
                <ReactRoutes />
                <ErrorDisplay />
            </BrowserRouter>
        </Provider>
    );
}

export default App;
