import { useState } from 'react';
import { directusApi } from '../api/directus';

interface UseDirectusState {
    loading: boolean;
    error: string | null;
}

export const useDirectus = () => {
    const [state, setState] = useState<UseDirectusState>({
        loading: false,
        error: null,
    });

    const uploadFile = async (file: File) => {
        setState({ loading: true, error: null });
        try {
            const response = await directusApi.uploadFile(file);
            setState({ loading: false, error: null });
            return response;
        } catch (error: any) {
            setState({ loading: false, error: error.message });
            return { success: false, error: error.message };
        }
    };

    const createTrainer = async (
        data: any,
        language: string,
        file_id: string | null,
    ) => {
        setState({ loading: true, error: null });
        try {
            const response = await directusApi.createTrainer(
                data,
                language,
                file_id,
            );
            setState({ loading: false, error: null });
            return response;
        } catch (error: any) {
            setState({ loading: false, error: error.message });
            return { success: false, error: error.message };
        }
    };

    const getTrainers = async () => {
        setState({ loading: true, error: null });
        try {
            const response = await directusApi.getTrainers();
            setState({ loading: false, error: null });
            return { success: true, data: response };
        } catch (error: any) {
            setState({ loading: false, error: error.message });
            return { success: false, error: error.message };
        }
    };

    return {
        ...state,
        uploadFile,
        createTrainer,
        getTrainers,
    };
};
