import Textfield from '../../components/InputFields/Textfield';
import Button from '../../components/InputFields/Button';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

import { useError } from '../../context/ErrorContext';
import { useEffect } from 'react';

import '../../styles/containers/login-form.scss';

import { useTranslation } from 'react-i18next';


import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [err, setErr] = useState(null);
    const { setErrorMessage, setSuccessMessage } = useError();

    const navigate = useNavigate();
    const { t } = useTranslation('login');

    useEffect(() => {
        const uuid = localStorage.getItem('uuid');
        console.log(uuid);
        if (uuid) {
            localStorage.removeItem('uuid');
            navigate('/de');
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = { email };

        try {
            const response = await fetch('https://api.swiss-cycling-jobboerse.ch/trainerfinder/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const data = await response.json();
                setSuccessMessage(t('login_success'));
            } else {
                console.error('Error:', response.statusText);
            }
        } catch (error) {
            setErrorMessage(t('login_error'));
            console.error('Network Error:', error);
        }
    };


    return (
        <div>
            <Header />

            <div className='login-form'>
                <h1>{t('Eintrag bearbeiten')}</h1>
                <p>{t('login_text')}</p>
                <form onSubmit={e => { handleSubmit(e) }}>
                    <Textfield value={email} onChange={setEmail} placeholder={t("Email")} />
                    <Button
                        type='submit'
                        value={t('Absenden')}
                    />
                </form>

            </div>
            <Footer />

        </div>

    )

        ;
};

export default LoginForm;