import React, { useEffect, useState } from 'react';
import '../../styles/pages/boerse.scss';

import { useNavigate, useParams, Link } from 'react-router-dom';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import PreviewContainer from '../../components/PreviewContainer/PreviewContainer';

import { useTranslation } from 'react-i18next';

export default function Boerse() {
    const { t } = useTranslation('erfassen-seite');

    const [data, setData] = useState(null);

    function getUUID() {
        const urlParams = new URLSearchParams(window.location.search);
        let uuid = urlParams.get('uuid');
        let token = urlParams.get('token');

        if (!uuid) {
            uuid = localStorage.getItem('uuid');
        } else {
            localStorage.setItem('uuid', uuid);
            localStorage.setItem(
                'uuid_timestamp',
                new Date().getTime().toString(),
            );
        }

        return uuid;
    }

    const getToken = () => {
        const urlParams = new URLSearchParams(window.location.search);
        let token = urlParams.get('token');

        if (!token) {
            token = localStorage.getItem('token');
        } else {
            localStorage.setItem('token', token);
        }

        return token;
    };

    useEffect(() => {
        const uuid = getUUID();
        const token = getToken();

        if (!uuid) {
            console.error('UUID is missing');
            return;
        }

        const fetchData = async () => {
            try {
                type Payload = {
                    uuid: string;
                    token?: string;
                };

                let payload: Payload = { uuid };

                if (token) {
                    payload['token'] = token;
                }

                const response = await fetch(
                    'https://api.swiss-cycling-jobboerse.ch/trainerfinder/get',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload),
                    },
                );

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const items = await response.json();

                const filteredItems = items.filter(
                    (item: any) => item.status !== 'deleted',
                );

                setData(filteredItems);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <Header />

            <div className='add-new'>
                <h2>{t('Eintrag bearbeiten')}</h2>
                <p>{t('eintrag_bearbeiten_text')}</p>

                {data === null ? (
                    <p>{t('kein_eintrag_text')}</p>
                ) : (
                    <PreviewContainer
                        data={data}
                        postType={'item'}
                    />
                )}
            </div>
            <Footer />
        </>
    );
}
