import React, { useEffect, useState } from 'react';
import './header.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

export default function Header() {

    const { t, i18n } = useTranslation('header');
    const { lang } = useParams();

    const navigate = useNavigate();

    const [language, setLanguage] = useState(lang);

    const isTrainerfinder = process.env.REACT_APP_IS_TRAINERFINDER === 'true';

    const jobboerseNavItems = [
        { title: t("Swiss Cycling Jobbörse"), link: `/${language}` },
        { title: t("Neuer Eintrag"), link: `/${language}/neuer-eintrag` },
    ];

    const trainerfinderNavItems = [
        { title: t("Swiss Cycling Trainer Finder"), link: `/${language}` },
        { title: t("Neuer Eintrag"), link: `/${language}/neuer-eintrag` },
        { title: t("Jobbörse"), link: `https://swiss-cycling-jobboerse.ch/${language}`, target: "__blank" },
    ];

    const mainNavItems = isTrainerfinder ? trainerfinderNavItems : jobboerseNavItems;

    function getUUID() {
        const urlParams = new URLSearchParams(window.location.search);
        let uuid = urlParams.get('uuid');
        const uuidTimestamp = localStorage.getItem('uuid_timestamp');

        if (uuidTimestamp && Date.now() - uuidTimestamp > 1800000) {
            localStorage.removeItem('uuid');
            localStorage.removeItem('uuid_timestamp');
            navigate(`/`);
        }

        if (!uuid) {
            uuid = localStorage.getItem('uuid');
        } else {
            localStorage.setItem('uuid', uuid);
            localStorage.setItem('uuid_timestamp', Date.now());
        }

        return uuid;
    }

    const getToken = () => {
        const urlParams = new URLSearchParams(window.location.search);
        let token = urlParams.get('token');

        if (!token) {
            token = localStorage.getItem('token');
        } else {
            localStorage.setItem('token', token);
        }
    }

    const uuid = getUUID();
    getToken();

    const metaNavItems = [
        { title: t("Swiss Cycling"), link: "https://swiss-cycling.ch", target: "__blank" },
    ];

    if (uuid) {
        mainNavItems.push({ title: t("Eintrag bearbeiten"), link: `/${lang}/eintrag-bearbeiten` });
        metaNavItems.push({ title: t("Logout"), link: `/${lang}/login` });
    } else {
        metaNavItems.push({
            title: t("Login"), link: `/${lang}/login`
        });
    }

    const languageItems = [
        { title: "DE", language: "de" },
        { title: "FR", language: "fr" },
    ];

    const changeLanguage = (language) => {
        let url = window.location.pathname;

        const regex = /^\/(fr|de)/;

        const newUrl = url.replace(regex, `/${language}`);

        i18n.changeLanguage(language);

        window.history.pushState({}, '', newUrl);
        setLanguage(language);
    };

    useEffect(() => {
        changeLanguage(lang);
    }, []);

    return (
        <header className="header">
            <div className="container">
                <div className="header--left">
                    <div className="meta-nav-wrapper">
                        <ul className="nav meta-nav">
                            {metaNavItems.map((item, index) => (
                                <li key={index} className="item">
                                    <Link to={item.link} target={item.target}>{item.title}</Link>
                                </li>
                            ))}
                            {languageItems.map((item, index) => (
                                <li key={index} className="item language">
                                    <span onClick={() => changeLanguage(item.language)}>{item.title}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="main-nav-wrapper">
                        <ul className="nav main-nav">
                            {mainNavItems.map((item, index) => (
                                <li key={index} className="item">
                                    <Link target={item.target} to={item.link}>{item.title}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="header--right">
                    <a href="https://swiss-cycling.ch" target="__blank">
                        <img src={process.env.PUBLIC_URL + "/assets/sc_logo.png"} alt="swiss cycling ausbildung logo" />
                    </a>
                </div>
            </div>
        </header>
    );
}
