import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchItems } from '../redux/thunks/itemThunks';
import type { RootState, AppDispatch } from '../redux/store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector = useSelector<RootState>;

export const usePosts = () => {
    const dispatch = useAppDispatch();
    const { trainerItems, loading, error } = useSelector(
        (state: RootState) => state.items,
    );

    useEffect(() => {
        dispatch(fetchItems());
    }, [dispatch]);

    const getItemById = (id: number) => {
        return trainerItems.find(item => item.id === id);
    };

    return { posts: trainerItems, loading, error, getItemById };
};
