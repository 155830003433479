import React, { useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import PreviewContainer from '../../components/PreviewContainer/PreviewContainer';
import '../../styles/pages/boerse.scss';
import { usePosts } from '../../hooks/usePosts';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import FilterGroup from '../../components/FilterGroup/FilterGroup';
import config from '../../components/FilterGroup/config';
import { useFilter } from '../../components/FilterGroup/context/FilterContext';

export default function TrainerBoerse() {
    const { t } = useTranslation(['erfassen-seite', 'config']);
    const navigate = useNavigate();
    const { posts, loading, error } = usePosts();
    const { setData, setFilterItems, visibleData } = useFilter();
    const location = useLocation();
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            navigate(`/de/modal/${id}`, {
                state: { background: location },
            });
        }
    }, [id, navigate, location]);

    const getFilterItems = (data: any, key: string) => {
        if (!data) return [];

        let processedData = [...data];

        if (key === 'organisationsform') {
            processedData = processedData.map((item: any) => ({
                ...item,
                [key]: item[key] === null ? t('Leiterperson') : item[key],
            }));
        }

        return processedData
            .filter(item => item[key] != null)
            .filter(
                (value, index, self) =>
                    index === self.findIndex(t => t[key] === value[key]),
            )
            .map(item => ({
                name: item[key],
                slug: item[key],
            }));
    };

    const getFilterItemsFromArray = (data: any, key: string) => {
        if (!data) return [];

        const flattenedItems = data.reduce((acc: any[], item: any) => {
            if (!item[key] || item[key] === '') return acc;

            try {
                if (item[key].includes('[')) {
                    const arr = JSON.parse(item[key]);
                    return [
                        ...acc,
                        ...arr.map((subItem: string) => ({
                            name: subItem,
                            slug: subItem,
                        })),
                    ];
                }

                return [...acc, { name: item[key], slug: item[key] }];
            } catch (error) {
                console.error(`Error processing ${key}:`, error);
                return acc;
            }
        }, []);

        return flattenedItems.filter(
            (value: any, index: any, self: any) =>
                index === self.findIndex((t: any) => t.slug === value.slug),
        );
    };

    // Process posts and set filters
    useEffect(() => {
        if (!posts || posts.length === 0) return;

        const filteredPosts = posts.filter(
            (item: any) => item.status !== 'deleted',
        );

        console.log('filteredPosts', filteredPosts);

        try {
            const tmpData = [...filteredPosts];
            setFilterItems(
                'disziplinen',
                getFilterItemsFromArray(tmpData, 'disziplinen'),
            );
            setFilterItems('wohnkanton', getFilterItems(tmpData, 'wohnkanton'));
            setFilterItems(
                'erfahrung',
                getFilterItemsFromArray(tmpData, 'erfahrung'),
            );
            setFilterItems(
                'angebot',
                getFilterItemsFromArray(tmpData, 'angebot'),
            );

            setFilterItems(
                'js_ausbildung',
                getFilterItemsFromArray(tmpData, 'js_ausbildung'),
            );
            setFilterItems(
                'scg_ausbildung',
                getFilterItemsFromArray(tmpData, 'scg_ausbildung'),
            );
            setFilterItems(
                'availability',
                getFilterItemsFromArray(tmpData, 'availability'),
            );
            setFilterItems(
                'sprachen',
                getFilterItemsFromArray(tmpData, 'sprachen'),
            );
            setFilterItems(
                'organisationsform',
                getFilterItems(tmpData, 'organisationsform'),
            );

            setData(filteredPosts);
        } catch (error) {
            console.error('Error setting filter data:', error);
        }
    }, [posts]);

    const filterCategories = config['item'].filterCategories.map(category => ({
        ...category,
        label: t(category.labelKey),
    }));

    if (loading) return <LoadingSpinner />;
    if (error) return <div>Error: {error}</div>;

    return (
        <>
            <Header />
            <div className='add-new'>
                <div>
                    <h1>{t('slogan')}</h1>
                    <h2>{t('Übersicht')}</h2>
                    <p>{t('erfassen_intro')}</p>
                    <h2>{t('info_nachwuchs_titel')}</h2>
                    <p>{t('info_nachwuchs')}</p>
                    <button
                        className='button'
                        onClick={() => navigate('neuer-eintrag')}
                    >
                        {t('Neuer Eintrag')}
                    </button>
                </div>

                <FilterGroup
                    filterCategories={filterCategories}
                    searchPlaceholder='Suche'
                />

                <PreviewContainer
                    data={visibleData}
                    postType={'item'}
                />
            </div>
            <Footer />
        </>
    );
}
